<template>
    <div>
        <div class="casecontent">
            <div class="caseimg">
                <img :src="nowInfo.img" alt="" />
            </div>
            <div class="case">
                <div class="facName fac">{{ nowInfo.title }}</div>
                <div
                    class="fac"
                    :class="item1.childtitle=='解决方案：'?'fac-else':''"
                    v-for="(item1, index1) in nowInfo.content"
                    :key="index1"
                >
                    <div class="factitle">{{ item1.childtitle }}</div>
                    <div class="resolve">
                        {{ item1.childcontent }}
                    </div>
                </div>
                <pagenation :total="total" @pagechange='pagechange'></pagenation>
            </div>
        </div>
    </div>
</template>

<script>
import pagenation from '../pagenation/pagenation.vue'
export default {
    components:{
        pagenation
    },
    name: 'news',
    props: {
        nowInfo: {
            type: Object
        }
    },
    data() {
        return {
            total:4
        }
    },
    created() {},
    methods: {
        pagechange(e) {
            this.$emit('pagechangeFather', e)
        }
    }
}
</script>

<style lang="scss" scoped>
.fac-else{
    // background: #00227d;
    position: relative;
    top: -20px;
}
/deep/ .ant-pagination-item {
    background-color: transparent;
    position: relative;
    border: none;
}
/deep/ .ant-pagination-item:before{
    content: '';
    display: block;
    width: 4px;
    height: 1px;
    position: absolute;
    top: 11px;
    left: -2px;
    background: #A9B3CC;
    opacity: 1;
}
/deep/ .ant-pagination-item-1:before{
    display: none;
}
/deep/ .ant-pagination-prev {
    .anticon {
        display: none;
    }
    visibility: none;
    background-image: url('../../assets/images/solution/left.png');
    background-size: 19px 5px;
    background-position-y: center;
    background-repeat: no-repeat;
}
/deep/ .ant-pagination-next {
    .anticon {
        display: none;
    }
    visibility: none;
    background-image: url('../../assets/images/solution/right.png');
    background-size: 19px 5px;
    background-position-y: center;
    background-repeat: no-repeat;
}
/deep/ .ant-pagination-item-active {
    width: 14px;
    height: 32px;
    font-size: 24px;
    font-family: HarmonyOS Sans SC;
    font-weight: 500;
    line-height: 36px;
    a {
        color: #111a37;
    }
}
.casecontent {
    width: 1280px;
    display: flex;
    justify-content: space-between;
    .caseimg {
        width: 763px;
        height: 480px;
        background-color: pink;
        border-radius: 4px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            // border-radius: 4px;
        }
    }
    .case {
        width: 480px;
        height: 480px;
        .fac {
            margin: 38px;
        }
        .facName {
            height: 32px;
            font-size: 24px;
            font-family: HarmonyOS Sans SC;
            font-weight: bold;
            color: #00227d;
            opacity: 1;
        }
        .factitle {
            height: 21px;
            font-size: 16px;
            font-family: HarmonyOS Sans SC;
            font-weight: bold;
            color: #111a37;
            margin: 16px 0;
        }
        .resolve {
            height: 103px;
            font-size: 14px;
            font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
            font-weight: 400;
            line-height: 28px;
            color: #111a37;
        }
    }
}
</style>
