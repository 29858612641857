<template>
    <div class="solution">
        <div class="solution-head">
            <h3>业务及产品</h3>
            <h4>BUSSINESS & PRODUCTS</h4>
        </div>
        <div class="maodian">
            <!-- <a-anchor @click="tobox" :affix='false'>
                <a-anchor-link
                :style="{
                        borderBottom:
                            rowtitle == item.title
                                ? '3px solid purple'
                                : '3px solid transparent'
                    }"
                    v-for="(item, index) in list"
                    :key="index"
                    :href="item.href"
                    :title="item.title"
                />
            </a-anchor> -->
            <anchor :list="list"></anchor>
        </div>
        <div class="product">
            <div class="left">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="" />
            </div>
            <div class="center" id="fbscmode">
                <img src="../assets/images/logo/圆6排6列.png" alt="" class="img-common">
                <div class="wineProduct">
                    <div class="wineTitle">FBSC模式</div>
                    <div style="margin:20px 0">
                        <img
                            src="../assets/images/business/point.png"
                            style="width:40px;height:20px"
                            alt=""
                        />
                    </div>
                    <div class="brand">FBSC MODE</div>
                </div>
                <div>
                    <div class="modeIntroduce">
                        酒星科技赋能生态链贯穿了白酒产业上中下游全链条，针对酒厂（Factory）、酒商（Business）、零售商户（Sale）、消费者（customer），围绕销售、品牌、产品、金融、技术五大版块，构建FBSC全生命周期赋能解决方案。
                        利用专业生产技术，品牌运营，市场营销，互联网技术，产业金融等手段，创新性地为酒厂，酒商，零售商户，消费者赋能。让酒企，酒商卖好酒，让消费者买到好酒。
                        真正成就白酒产业从业企业及从业者，成就真实好酒
                    </div>
                    <div class="plate">
                        <div
                            :class="
                                itemindex == index ? 'plateItemhover' : 'plateItem'
                            "
                            @click="classchange(index)"
                            v-for="(item, index) in typeList"
                            :key="index"
                        >
                            <div class="plateContent">
                                <div
                                    :class="[
                                        'icon',
                                        {
                                            jc: item.classname == 'jc',
                                            jxs: item.classname == 'jxs',
                                            lszd: item.classname == 'lszd',
                                            xfz: item.classname == 'xfz'
                                        }
                                    ]"
                                ></div>
                                <div class="name">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="platepoint" v-if="itemindex == 0">
                        <div class="title">
                            <div>FACTORY</div>
                            <div>酒厂</div>
                        </div>
                        <div class="contentpoint">
                            <div
                                v-for="(item, index) in detailList"
                                :key="index"
                                class="pointItem"
                            >
                                <div style="margin-right:15px">
                                    <img
                                        :src="item.icon"
                                        alt=""
                                        style="width:20px;height:20px"
                                    />
                                </div>
                                <div>{{ item.describe }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="platepoint" v-else-if="itemindex == 1">
                        <div class="title">
                            <div>BUSINESS</div>
                            <div>经销商</div>
                        </div>
                        <div class="contentpoint">
                            <div
                                v-for="(item, index) in detailList2"
                                :key="index"
                                class="pointItem"
                            >
                                <div style="margin-right:15px">
                                    <img
                                        :src="item.icon"
                                        alt=""
                                        style="width:20px;height:20px"
                                    />
                                </div>
                                <div>{{ item.describe }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="platepoint" v-else-if="itemindex == 2">
                        <div class="title">
                            <div>SALE</div>
                            <div>零售终端</div>
                        </div>
                        <div class="contentpoint">
                            <div
                                v-for="(item, index) in detailList3"
                                :key="index"
                                class="pointItem"
                            >
                                <div style="margin-right:15px">
                                    <img
                                        :src="item.icon"
                                        alt=""
                                        style="width:20px;height:20px"
                                    />
                                </div>
                                <div>{{ item.describe }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="platepoint" v-else>
                        <div class="title">
                            <div>CUSTOMER</div>
                            <div>消费者</div>
                        </div>
                        <div class="contentpoint">
                            <div
                                v-for="(item, index) in detailList4"
                                :key="index"
                                class="pointItem"
                            >
                                <div style="margin-right:15px">
                                    <img
                                        :src="item.icon"
                                        alt=""
                                        style="width:20px;height:20px"
                                    />
                                </div>
                                <div>{{ item.describe }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <img src="../assets/images/logo/科技线@2x.png" alt="" />
            </div>
        </div>
        <div class="operating" id="fiveAbility">
            <div class="left">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="" />
            </div>
            <div class="center">
                <img src="../assets/images/logo/圆6排6列.png" alt="" class="img-common">
                <div class="wineProduct">
                    <div class="wineTitle">产业五大赋能</div>
                    <div style="margin:20px 0">
                        <img
                            src="../assets/images/business/point.png"
                            style="width:40px;height:20px"
                            alt=""
                        />
                    </div>
                    <div class="brand">FIVE EMPWER</div>
                </div>
                <div class="empwer">
                    <div
                        class="empwerItem"
                        v-for="(item, index) in empList"
                        :key="index"
                    >
                        <div class="itemContent">
                            <div class="icon">
                                <img :src="item.icon" alt="" />
                            </div>
                            <div class="name">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <img src="../assets/images/logo/科技线@2x.png" alt="" />
            </div>
        </div>
        <div id="lifeSolution">
            <div class="left">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="" />
            </div>
            <div class="center">
                <img src="../assets/images/logo/圆6排6列.png" alt="" class="img-common">
                <div class="wineProduct">
                    <div class="wineTitle" style="width:500px">
                        全生命周期解决方案
                    </div>
                    <div style="margin:20px 0">
                        <img
                            src="../assets/images/business/point.png"
                            style="width:40px;height:20px"
                            alt=""
                        />
                    </div>
                    <div class="brand">INDUSTRY SOLUTION</div>
                </div>
                <div style="width:1275;height:482px">
                    <img
                        style="width:1275;height:482px"
                        src="../assets/images/solution/solution.png"
                        alt=""
                    />
                </div>
            </div>
            <div class="right">
            </div>
        </div>
        <div id="advantage">
            <div class="left">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="" />
            </div>
            <div class="center" style="position:relative;">
                <img src="../assets/images/logo/圆9排10列.png" alt="" class="img-circle">
                <div class="wineProduct">
                    <div class="wineTitle">我们的优势</div>
                    <div style="margin:20px 0">
                        <img
                            src="../assets/images/business/point.png"
                            style="width:40px;height:20px"
                            alt=""
                        />
                    </div>
                    <div class="brand">ADVANTAGE</div>
                </div>
                <Accordion :advantageList="advantageList"></Accordion>
            </div>
            <div class="right">
                <img src="../assets/images/logo/科技线@2x.png" alt="" />
            </div>
        </div>
        <div id="caseshow">
            <div class="left">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="" />
            </div>
            <div
                class="center"
            >
                <div class="wineProduct">
                    <div class="wineTitle">案例展示</div>
                    <div style="margin:20px 0">
                        <img
                            src="../assets/images/business/point.png"
                            style="width:40px;height:20px"
                            alt=""
                        />
                    </div>
                    <div class="brand">CASE SHOW</div>
                </div>
                <news :nowInfo="nowInfo" :infoList="infoList" @pagechangeFather="pagechangeFather"></news>
            </div>
            <div class="right">
            </div>
        </div>
    </div>
</template>

<script>
import anchor from '../components/anchor/anchor.vue'
import news from '../components/news/news.vue'
import pagenation from '../components/pagenation/pagenation.vue'
import Accordion from '../components/accordion/accordion.vue'
export default {
    name: 'solution',
    components: {
        anchor,
        news,
        pagenation,
        Accordion
    },
    data() {
        return {
            total: 4,
            list: [
                {
                    title: 'FBSC模式',
                    href: '#fbscmode'
                },
                {
                    title: '产业五大赋能',
                    href: '#fiveAbility'
                },
                {
                    title: '全生命周期解决方案',
                    href: '#lifeSolution'
                },
                {
                    title: '我们的优势',
                    href: '#advantage'
                },
                {
                    title: '案例展示',
                    href: '#caseshow'
                }
            ],
            typeList: [
                {
                    name: '酒厂',
                    img: '../assets/images/solution/jiuchang.png',
                    classname: 'jc'
                },
                {
                    name: '经销商',
                    img: '../assets/images/solution/package.png',
                    classname: 'jxs'
                },
                {
                    name: '零售终端',
                    img: '../assets/images/solution/house.png',
                    classname: 'lszd'
                },
                {
                    name: '消费者',
                    img: '../assets/images/solution/user.png',
                    classname: 'xfz'
                }
            ],
            itemindex: 0,
            detailList: [
                {
                    describe: '销售赋能：酒体销售合作、全网运营支持',
                    icon: require('../assets/images/solution/销售.png')
                },
                {
                    describe: '品牌赋能：酒厂品牌升级、产品线打造',
                    icon: require('../assets/images/solution/R.png')
                },
                {
                    describe: '产品赋能：生产技术优化、酒体升级优化',
                    icon: require('../assets/images/solution/bag.png')
                },
                {
                    describe: '金融赋能：供应链金融服务',
                    icon: require('../assets/images/solution/coin.png')
                },
                {
                    describe: '技术赋能：系统软件支持',
                    icon: require('../assets/images/solution/rl.png')
                }
            ],
            detailList2: [
                {
                    describe: '销售赋能：产品采销合作',
                    icon: require('../assets/images/solution/销售.png')
                },
                {
                    describe: '品牌赋能：自有品牌打造、定制酒开发',
                    icon: require('../assets/images/solution/R.png')
                },
                {
                    describe: '产品赋能：高品质、高利润产品优选',
                    icon: require('../assets/images/solution/bag.png')
                },
                {
                    describe: '金融赋能：专属金融支持',
                    icon: require('../assets/images/solution/coin.png')
                },
                {
                    describe: '技术赋能：交易、管理软件支持',
                    icon: require('../assets/images/solution/rl.png')
                }
            ],
            detailList3: [
                {
                    describe: '销售赋能：成本优化；产品丰富；知识',
                    icon: require('../assets/images/solution/销售.png')
                },
                {
                    describe: '金融赋能：精准金融支持',
                    icon: require('../assets/images/solution/coin.png')
                },
                {
                    describe: '金融赋能：精准金融支持',
                    icon: require('../assets/images/solution/rl.png')
                }
            ],
            detailList4: [
                {
                    describe: '金融赋能：精准金融支持',
                    icon: require('../assets/images/solution/销售.png')
                },
                {
                    describe: '金融赋能：精准金融支持',
                    icon: require('../assets/images/solution/coin.png')
                }
            ],
            empList: [
                {
                    icon: require('../assets/images/solution/wmoney.png'),
                    name: '销售赋能'
                },
                {
                    icon: require('../assets/images/solution/wbag.png'),
                    name: '产品赋能'
                },
                {
                    icon: require('../assets/images/solution/wR.png'),
                    name: '品牌赋能'
                },
                {
                    icon: require('../assets/images/solution/wcoin.png'),
                    name: '金融赋能'
                },
                {
                    icon: require('../assets/images/solution/wrl.png'),
                    name: '技术赋能'
                }
            ],
            nowInfo: {
                img: require('../assets/images/solution/case.png'),
                title: '李渡酒业',
                content: [
                    {
                        childtitle: '需求诊断：',
                        childcontent:
                            '江西李渡酒业，以元代国宝窖池、国内最贵光瓶酒知晓于行业内，以区域市场+电商销售为主，市场认知度较低，渠道铺设有限。'
                    },
                    {
                        childtitle: '解决方案：',
                        childcontent:
                            '销售运营赋能；由酒星负责代理经销李渡系列产品，铺设流通渠道，同时进行全网品牌宣传运营，包括全网自媒体内容运营、流媒体内容运营等。双方联合开发李渡全新产品线-鱼米丰华，由酒星负责产品开发及全盘销售运作。'
                    }
                ]
            },
            infoList:[
                {
                    img: require('../assets/images/solution/case.png'),
                    title: '李渡酒业',
                    content: [
                        {
                            childtitle: '需求诊断：',
                            childcontent:
                                '江西李渡酒业，以元代国宝窖池、国内最贵光瓶酒知晓于行业内，以区域市场+电商销售为主，市场认知度较低，渠道铺设有限。'
                        },
                        {
                            childtitle: '解决方案：',
                            childcontent:
                                '销售运营赋能；由酒星负责代理经销李渡系列产品，铺设流通渠道，同时进行全网品牌宣传运营，包括全网自媒体内容运营、流媒体内容运营等。双方联合开发李渡全新产品线-鱼米丰华，由酒星负责产品开发及全盘销售运作。'
                        }
                    ] 
                },
                {
                    img: require('../assets/images/logo/图片1@2x.png'),
                    title: '仙酿酒业',
                    content: [
                        {
                            childtitle: '需求诊断：',
                            childcontent:
                                '贵州省仁怀市仙酿酒业有限公司，坐落于酱酒核心产区，年产大曲酱香型白酒2000余吨，库存大曲酱香基酒5000余吨。以基酒生产为主，销售渠道有限，市场能力薄弱，资金周全压力极大。'
                        },
                        {
                            childtitle: '解决方案：',
                            childcontent:
                                '深度销售赋能；双方共同成立销售公司，由酒星负责全盘销售运营，围绕基酒销售、酒体升级、产品打造、产品销售、渠道铺设、全国招商、运营推广，展开全线运作。'
                        }
                    ] 
                },
                {
                    img: require('../assets/images/logo/image34@2x.png'),
                    title: '金沙领酱',
                    content: [
                        {
                            childtitle: '需求诊断：',
                            childcontent:
                                '金沙领酱为金沙全国最大经销商之一，团队位于山东，拥有金沙品牌旗下5款子产品，但产品缺乏产品力，包装老旧。'
                        },
                        {
                            childtitle: '解决方案：',
                            childcontent:
                                '产品升级赋能，打造子产品独特产品文化内涵，由产品文化内涵延展产品包装设计。'
                        }
                    ] 
                },
                {
                    img: require('../assets/images/logo/22-2@2x.png'),
                    title: '酒润酒业',
                    content: [
                        {
                            childtitle: '需求诊断：',
                            childcontent:
                                '美赢集团旗下子公司，主要负责白酒传统电商业务，经过近年的迅猛发展，面临着电商平台流量渠道多元化、客户忠诚度低、运营成本高、客服团队管理困难等痛点。'
                        },
                        {
                            childtitle: '解决方案：',
                            childcontent:
                                '智能系统技术赋能；通过打通各大电商平台，统一各割裂渠道数据，进行用户画像的去重、分析和沉淀，达成一站式客户关系管理，建立自有会员体系，实现口碑营销；结合大数据商业智能辅助决策，全渠道营销直达客户，降低运营成本投入，提升销售业绩。'
                        }
                    ] 
                },
            ],
            advantageList:[
                {title:'线上运营+线下销售',
                contentA:'线上运营网络：已布局全网销售渠道，通过自媒体矩阵运营、媒介资源整合、联合推广、数据分析模型等传递品牌及产品价值，已实现全网媒介平台覆盖，自运营自媒体账号全网粉丝达20W+。',
                contentB:'线下销售网络：覆盖9万+行业客户，深度合作渠道5千+，拥有10万+忠实消费者，业务覆盖28个省份，374个市级城市。',
                bgSrc: require('@/assets/images/logo/蒙版组31@2x.png')
                },
                {title:'顶尖的酒星专家团队',
                contentA:'行业顶级酒体专家顾问团队，从酒体品鉴、酒体勾调、生产技术升级，深度赋能产品，已链接30+家酒厂。',
                contentB:'',
                bgSrc: require('@/assets/images/logo/蒙版组35@2x.png')
                },
                {title:'专业的酒星品牌团队',
                contentA:'20+位行业经验丰富的品牌策划及设计人员，从品牌定位、产品策略、包装设计、运营销售，全生命周期赋能品牌及产品',
                contentB:'',
                bgSrc: require('@/assets/images/logo/蒙版组34@2x.png')
                },
                {title:'领先的酒星开发团队',
                contentA:'30+国内领先的互联网技术开发团队；开发系统涵盖交易系统、业务管理系统、支付系统、智慧金融系统、智能决策系统等；运用涵盖互联网技术、区块链技术、AI技术、大数据、物联网等。',
                contentB:'',
                bgSrc: require('@/assets/images/logo/蒙版组33@2x.png')
                },
                {title:'雄厚的酒星金融实力',
                contentA:'目前累计放款额达1.5亿+，服务客户300+，日均放款额达100万+。',
                contentB:'',
                bgSrc: require('@/assets/images/logo/蒙版组32@2x.png')
                },
            ]
        }
    },
    methods: {
        classchange(i) {
            this.itemindex = i
        },
        pagechangeFather(e) {
            this.nowInfo = this.infoList[e-1]
        }
    }
}
</script>

<style lang="scss" scoped>
    .solution-head {
        margin-top: 100px;
        width: 100%;
        height: 400px;
        padding-left: calc((100% - 1280px) / 2);
        letter-spacing: 8px;
        box-sizing: border-box;
        background: url('../assets/images/solution/banner.png');
        background-size: 100% 400px;
        background-repeat: no-repeat;
        font-size: 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
            color: #fff;
            font-weight: bold;
        }
        h4{
            color: #fff;
            font-size: 30px;
            font-weight: bold;
        }
    }
.maodian {
    border-bottom: 1px solid #f0f2f5;
    height: 69px;
    li {
        list-style: none;
        // width: 96px;
        line-height: 69px;
        margin-right: 30px;
        font-family: HarmonyOS Sans SC;
        color: #111a37;
        font-weight: bold;
        font-size: 16px;
        border-bottom: 3px solid transparent;
        cursor: pointer;
    }
}
.product {
    width: 100%;
    display: flex;
    padding: 120px 0 140px 0;
    position: relative;
    .left, .right{
        width: calc((100% - 1280px) / 2);
        img {
            width: 100%;
        }
    }
    .left{
        margin-top: 22px;
    }
    .right{
        margin-top: 710px;
    }
    .modeIntroduce {
        width: 1280px;
        height: 103px;
        font-size: 14px;
        font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
        font-weight: 400;
        line-height: 28px;
        color: #111a37;
        opacity: 1;
    }
    .plate {
        width: 1280px;
        height: 251px;
        display: flex;
        margin-top: 30px;
        justify-content: space-between;
        background: #ffffff;
        .plateItem {
            cursor: pointer;
            position: relative;
            width: 299px;
            height: 251px;
            box-shadow: 0 0 10px rgb(240, 240, 240);
            .plateContent {
                margin: 50px auto;
                text-align: center;
                .icon {
                    height: 76px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position-x: center;
                }
                .jc {
                    background-image: url('../assets/images/solution/icon_jiuchang.png');
                }
                .jxs {
                    background-image: url('../assets/images/solution/icon_package.png');
                }
                .lszd {
                    background-image: url('../assets/images/solution/icon_house.png');
                }
                .xfz {
                    background-image: url('../assets/images/solution/icon_user.png');
                }
                .name {
                    font-size: 24px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: bold;
                    text-align: center;
                    line-height: 76px;
                    color: #111a37;
                }
            }
        }
        .plateItemhover {
            cursor: pointer;
            background-color: #0037cc;
            box-shadow: 0px 10px 40px rgba(71, 121, 255, 0.2);
            border-radius: 4px;
            position: relative;
            width: 299px;
            height: 251px;
            .plateContent {
                margin: 50px auto;
                text-align: center;
                .icon {
                    height: 76px;
                    text-align: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position-x: center;
                }
                .jc {
                    background-image: url('../assets/images/solution/jiuchang.png');
                }
                .jxs {
                    background-image: url('../assets/images/solution/package.png');
                }
                .lszd {
                    background-image: url('../assets/images/solution/house.png');
                }
                .xfz {
                    background-image: url('../assets/images/solution/user.png');
                }
                .name {
                    font-size: 24px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: bold;
                    text-align: center;
                    line-height: 76px;
                    color: white;
                }
            }
        }
        .plateItemhover:after {
            content: url('../assets/images/solution/sanjiaoxing.png');
            position: absolute;
            display: block;
            top: 249px;
            left: 45%;
        }
    }
    .platepoint {
        width: 1280px;
        height: 190px;
        // background-color: pink;
        margin-top: 20px;
        box-shadow: 0 0 10px #e4e4e4;
        position: relative;
        .title {
            width: 97px;
            height: 62px;
            width: 1280px;
            font-size: 20px;
            font-family: HarmonyOS Sans SC;
            font-weight: bold;
            line-height: 36px;
            color: #111a37;
            opacity: 1;
            position: absolute;
            top: 60px;
            left: 60px;
        }
        .contentpoint {
            width: 1000px;
            height: 62px;
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            top: 68px;
            right: 30px;
            .pointItem {
                display: flex;
                // justify-content: center;
                align-items: center;
                width: 330px;
                div {
                    font-size: 14px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    line-height: 28px;
                    color: #111a37;
                    opacity: 1;
                }
            }
        }
    }
    .center{
        position: relative;
        .img-common{
            width: 200px;
            height: 200px;
            position: absolute;
            right: -66px;
            top: 275px;
            z-index: -1;
        }
    }
}
.wineProduct {
    width: 321px;
    .wineTitle {
        width: 283px;
        height: 63px;
        font-size: 46px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        line-height: 59px;
        color: #00227d;
    }
    .brand {
        width: 321px;
        height: 93px;
        font-size: 26px;
        font-family: HarmonyOS Sans SC;
        font-weight: 900;
        line-height: 59px;
        color: #2b4079;
        opacity: 0.2;
    }
}
.operating{
    width: 100%;
    display: flex;
    padding-bottom: 140px;
    .left, .right{
        width: calc((100% - 1280px) / 2);
        img {
            width: 100%;
        }
    }
    .left{
        margin-top: 22px;
    }
    .right{
        margin-top: 408px;
    }
    .center{
        position: relative;
        .img-common{
            width: 200px;
            height: 200px;
            position: absolute;
            right: -76px;
            top: 142px;
            z-index: -1;
        }
    }
}
.empwer {
    width: 1280px;
    height: 262px;
    display: flex;
    justify-content: space-between;
    position: relative;
    background: #ffffff;
    .empwerItem {
        width: 234px;
        margin-top: 0px;
        transition: margin-top 0.5s ease;
        height: 262px;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        box-shadow: 0 0 10px #e9e9e9;
        .itemContent {
            width: 100px;
            .icon {
                width: 100px;
                line-height: 100px;
                margin-top: 20px;
                // background-image: url('../assets/images/solution/money.png');
                background: #00227d;
                border-radius: 50%;
                text-align: center;
                img {
                    width: 50px;
                    height: 50px;
                }
            }
            .name {
                width: 100px;
                margin-top: 30px;
                height: 32px;
                font-size: 24px;
                font-family: HarmonyOS Sans SC;
                font-weight: bold;
                color: #111a37;
            }
        }
    }
    .empwerItem:hover {
        // position: absolute;
        margin-top: -10px;
    }
}

#lifeSolution {
    padding: 140px 0;
    width: 100%;
    background-image: url('../assets/images/solution/bcimg.png');
    display: flex;
    position: relative;
    z-index: -1;
    .left, .right{
        width: calc((100% - 1280px) / 2);
        img {
            width: 100%;
        }
    }
    .left{
        margin-top: 25px;
    }
    .center{
        position: relative;
        .img-common{
            width: 200px;
            height: 200px;
            position: absolute;
            right: -10px;
            top: 0px;
            z-index: 0;
        }
    }
}
#advantage {
    padding: 140px 0;
    width: 100%;
    display: flex;
    .left, .right{
        width: calc((100% - 1280px) / 2);
        img {
            width: 100%;
        }
    }
    .left{
        margin-top: 22px;
    }
    .right{
        margin-top: 600px;
    }
    .center{
        position: relative;
        .img-circle{
            width: 326px;
            height: 319px;
            position: absolute;
            right: -100px;
            top: 100px;
        }
    }
}
#caseshow {
    width: 100%;
    background-image: url('../assets/images/solution/bcimg.png');
    background-size: 100% 100%;
    padding: 140px 0;
    display: flex;
    .casecontent {
        width: 1280px;
        display: flex;
        justify-content: space-between;
    }
    .left, .right{
        width: calc((100% - 1280px) / 2);
        img {
            width: 100%;
        }
    }
    .left{
        margin-top: 22px;
    }
}
.center {
    width: 1280px;
    margin: 0 auto;
}
.underLine {
    list-style: none;
    // width: 96px;
    line-height: 69px;
    margin-right: 30px;
    font-family: HarmonyOS Sans SC;
    color: #111a37;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 3px solid purple;
}
</style>
